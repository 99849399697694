require.context('../images', true, /\.(png|jpe?g|svg|tiff)$/);
require.context('../docs', true, /\.(pdf)$/);

require('@paridev/parinw/js/load_fonts');
require('@paridev/parinw/js/bootstrap');

require('waypoints/lib/noframework.waypoints');

require('@paridev/parinw/js/cookie_banner');
require('@paridev/parinw/js/go_top');

require('@paridev/parinw/js/ls');
